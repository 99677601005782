// import logo from './logo.svg'; cool that you can import svgs!.
import './App.css';

import { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { useMediaQuery } from 'react-responsive'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, Table } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Link as NavLink } from "react-scroll";


const citations = require("./citations.json");
const team = require("./team.json");

const data = require("./content.json");

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: { //235eae //#2952a2
      main: '#2561b3',
    }
  },
  typography: {
    fontFamily: 'sans-serif',
  },  
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'sans-serif';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  },
});

const mail = <div style={{marginTop:20, backgroundColor:"#ffffffa0", width:200, padding:10, textAlign:"center"}}><table><tbody><tr>
                  <td style={{textAlign:"right"}}><EmailIcon /></td>
                  <td><Link target='_blank' href="mailto:contact@datasky.uk?subject=New enquiry">contact@datasky.uk</Link></td>
                  </tr></tbody></table></div>


function cardHolder(content, image, width, contentAlign = "left") {

  var backgroundStyle = { display: 'flex', marginTop: 2, marginLeft: 2, marginRight: 2, padding:2, paddingRight:0, 
                          backgroundImage: 'linear-gradient(to right, #ffffffef 0 65%, #ffffff00 90% 100%)' + ( image ? ', url("'+image+'")' : "" ),
                          backgroundSize: "cover",
                          backgroundPositionY:"50%",
                          backgroundRepeat: "no-repeat"
                        }
  var contentStyle = { display: 'flex', flexDirection: 'column', paddingRight:5, width: width ? width: "70%" }

  if( contentAlign === "right"){

    backgroundStyle.backgroundImage = 'linear-gradient(to left, #ffffffef 0 65%, #ffffff00 90% 100%)' + ( image ? ', url("'+image+'")' : "" )

    contentStyle.marginLeft = "30%"

  }
  

  return <Card sx={backgroundStyle}>
            <Box sx={contentStyle}>
              {content}
            </Box>        
          </Card>

}



function contactCard(){

  return cardHolder(<CardContent sx={{ flex: '1 0 auto', textAlign: "left" }}>
                      
                      <Typography component="div" variant="h4" color="secondary" sx={{marginBottom:2}}>
                        <div> <b>datasky limited</b> </div>
                      </Typography>

                      <Typography component="div" variant="h5" color="primary" sx={{marginBottom:2}}>
                        <div>Glasgow, Scotland</div>
                        <div><EmailIcon /> <Link target='_blank' href="mailto:contact@datasky.uk?subject=New enquiry">contact@datasky.uk</Link></div>
                        <div><TwitterIcon /> <Link target='_blank' href="https://twitter.com/dataskyuk">@dataskyuk</Link></div>
                        <div><LinkedInIcon /> <Link target='_blank' href="https://www.linkedin.com/company/datasky-uk/">/datasky-uk</Link></div>
                      </Typography>
                    </CardContent>)
}




function researchCard( citations, rightAlign=false ){

  return cardHolder(<CardContent >
                      <Typography component="div" variant="span" color="secondary" sx={{marginBottom:2}} > 
                      <Table style={{width:"100%"}}>
                        <tbody>{                      
                          citations.map( (citation,c) => {
                            return <tr key={"cit"+c} >
                              <td style={{verticalAlign:"top",textAlign:"left"}}>
                                <b>{citation.Year}</b>
                                </td>
                              <td style={{verticalAlign:"top",textAlign:"left", paddingLeft:20, paddingBottom:15}}>
                                <div style={{color:"black"}}>{citation.Authors}</div>
                                <div><b style={{}}><Link color={"secondary"} href={citation.Url} target='_blank' >{citation.Title}</Link></b></div>
                                </td>
                              </tr>
                          })
                        }</tbody>
                        </Table>
                      </Typography>
                    </CardContent>, null, "100%")            
}



function offerCard( content, contentAlign= "right" ){

  return cardHolder(<CardContent sx={{ flex: '1 0 auto', textAlign: "left" }}>
                      { content.title ? <Typography component="div" variant="h4" color="secondary" sx={{marginBottom:2}} dangerouslySetInnerHTML={{__html:content.title}} /> : "" }
                      { content.body ? <Typography className="cardText" variant="body1" color="common.black" component="div"  dangerouslySetInnerHTML={{__html:content.body}} sx={{lineHeight:1.6}}/> : ""}                    
                      { content.mail ? mail : "" }
                    </CardContent>, content.image, null, contentAlign )
}


function teamCard( team, rightAlign=false, mobile=false ){

  var content = <Table style={{width:"100%"}}>
        <tbody>{                      
          team.map( (teamMember,c) => {
            return <tr key={"team"+c} >
              <td style={{verticalAlign:"top",textAlign:"left", width:"25%"}}>
                <b>{teamMember.name}</b>
                </td>
              <td style={{verticalAlign:"top",textAlign:"left", paddingLeft:20, paddingBottom:15, width:"60%"}}>
                <div style={{fontWeight:"bold"}}>{teamMember.role}</div>
                <div style={{color:"black", paddingTop:10,paddingRight:50}}>{teamMember.bio}</div>
                </td>
              <td style={{width:"15%"}}>
                <img src={teamMember.image} style={{width:250, borderRadius:120}} alt={teamMember.name+"'s photo"}></img>
              </td>
              </tr>
          })
        }</tbody>
        </Table>

  if ( mobile ) {
    content = <div>
      {
        team.map( (teamMember,c) => {
          return <div key={"team"+c} style={{textAlign:"center"}}>
            <img src={teamMember.image} style={{width:250, borderRadius:120}} alt={teamMember.name+"'s photo"}></img>
            <div><b>{teamMember.name}</b></div>
            <div style={{fontWeight:"bold"}}>{teamMember.role}</div>
            <div style={{color:"black", paddingTop:10, textAlign:"left"}}>{teamMember.bio}</div>
            <hr/>
            </div>
        })
      }
    </div>
  }


  return cardHolder(<CardContent >
                      <Typography component="div" variant="body1" color="secondary" sx={{marginBottom:2}} > 
                      {content}
                      </Typography>
                    </CardContent>, null, "100%")
}


function groupTitles(id,title){
  return <div>
      <div id={id} ></div>
      <Typography component="div" variant="h4" color="common.white" sx={{fontSize: "2.5rem",marginLeft:1,marginTop:3, marginBottom:2, textAlign:"left", fontWeight:"bold"}} > {title} </Typography>
    </div>
}


function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const isBigScreen = useMediaQuery({ query: '(min-width: 900px)' })

  var appBar = <AppBar position="fixed" className='appbar' sx={{backgroundColor:"#efefef"}}>
                <Toolbar >
                  <NavLink to={"top"} className='barLink' >
                    <img src="datasky_logo.png" className="App-logo" alt="logo" style={{height:45, marginLeft:0}}/>
                  </NavLink>
                          
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    
                    {
                      [ {title:"Services",link:"top"},                
                        {title:"Team",link:"team"},
                        {title:"Collaborators",link:"collaborators"},
                        {title:"Research",link:"research"},
                        {title:"Contact",link:"contact"}
                      ].map( 
                          (linkData) => 
                            <NavLink 
                              className='barLink'
                              key={linkData.title}
                              activeClass='active' 
                              spy 
                              to={linkData.link} 
                              offset={-70}
                            >
                                {linkData.title} 
                            </NavLink> 
                        )
                    }
                    
                  </Typography>
                </Toolbar>
              </AppBar>


  if ( !isBigScreen ){
    appBar  = <AppBar position="fixed" className='appbar' sx={{backgroundColor:"#efefef", color:"black"}}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}            
          >
            <MenuIcon />
          </IconButton>
         
          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List >

              
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  
                  {
                    [ {title:"Services",link:"top"},                
                      {title:"Team",link:"team"},
                      {title:"Collaborators",link:"collaborators"},
                      {title:"Research",link:"research"},
                      {title:"Contact",link:"contact"}
                    ].map( 
                        (linkData, l) => 
                        <ListItem key={"navitem-"+l} ><NavLink 
                            className='barLink'
                            key={linkData.title}
                            activeClass='active' 
                            spy 
                            to={linkData.link} 
                            offset={-70}
                            onClick={ () => {setIsDrawerOpen(false);}}
                          >
                              {linkData.title} 
                          </NavLink> 
                          </ListItem>
                      )
                  }
                  
                </Typography>

            </List>
          </Drawer>

          
        </Toolbar>

        <span style={{float:"right", position:'absolute', right:0, margin:8}}><img src="datasky_logo.png" className="App-logo" alt="logo" style={{height:40, marginLeft:0}}/></span>
      </AppBar>
      }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      {appBar}

      <div className='page_container'>
          
          {
            groupTitles("what","Services")
          }
          
          {
            data.content.map( (cont, c) => <span key={"offer"+cont.title}>{offerCard(cont, cont.side)}</span> )
          }

          {
            groupTitles("team","Team")
          }

          {
            teamCard(team.team, false, !isBigScreen)
          }

          {
            groupTitles("collaborators","Collaborators")
          }

          {
            teamCard(team.collaborators, false, !isBigScreen)
          }
          

          {
            groupTitles("research","Research")
          }

          {
            researchCard(citations)
          }

          {
            groupTitles("contact","Contact")
          }
          
          {
            contactCard()
          }

          <div id="downHere" style={{marginBottom:5, marginTop:700}}></div>
      </div>

      <Card style={{height:50, borderRadius:0, marginTop: 15 }}> <h4 style={{marginTop:15}}>{"© 2023-"+( new Date().getFullYear()+1 )  +" Datasky. Company no: 768898. All Rights Reserved."}</h4></Card>
      
      </ThemeProvider>
    </div>
  );
}

export default App;
